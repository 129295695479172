import { Action } from "redux-saga"

import { IOperationResultOutput } from "@api/schema"
import { IStateChangeDTO } from "@api/schema-dto"
import { IFormikActions } from "@basics/form"
import { idFromIModelOrIRI } from "@basics/util-importless"

import { ILockableModel } from "./schema-definitions"

/** result string expected from the API when object was locked */
export const LOCKED_API_RESULT = "Object locked"

/** result string expected from the API when object was locked */
export const UNLOCKED_API_RESULT = "Object unlocked"


/**
 * Definition of the interaction with the host application backend API for performing lock/unlock processes
 * to be implemented by the client
 */
export interface ILockUnlockEntityApi<Output = IOperationResultOutput> {
  /**
   * Locks a lockable entity by addressing its /lock endpoint.
   *
   * @param lockableEntity an entity that is lockable/unlockable
   * @param stateChangeDto message + internalNote to comment the state change
   * @returns a standard operation result output
   */
  lockEntity: (lockableEntity: ILockableModel, stateChangeDto: IStateChangeDTO) => Promise<Output>

  /**
   * Unlocks a lockable entity by addressing its /unlock endpoint.
   *
   * @param lockableEntity an entity that is lockable/unlockable
   * @param stateChangeDto message + internalNote to comment the state change
   * @returns a standard operation result output
   */
  unlockEntity: (lockableEntity: ILockableModel, stateChangeDto: IStateChangeDTO) => Promise<Output>
}

/**
 * This enum defines the usecases for locking/unlocking.
 */
export enum LockUsecases {
  LockEntity = "_lock_",
  UnlockEntity = "_unlock_",
}

/**
 * Action type for locking/unlocking an entity.
 */
export interface ILockUnlockAction extends Action {
  /** formik actions to interact with the triggering form */
  callbackActions: IFormikActions
  /** entity to be locked/unlocked */
  entity: ILockableModel
  /** message to be published to the entity owner */
  message: string
  /** internal note for the managers of the object */
  internalNote: string
  /** type of the action */
  type: LockUsecases
}

/**
 * Creates an ILockUnlockAction to lock/unlock an lockable object.
 */
const lockOrUnlockModelAction = (
  lockOrUnlock: LockUsecases,
  entity: ILockableModel,
  messageToTheOwner: string,
  internalNote: string,
  actions: IFormikActions
): ILockUnlockAction => ({
  callbackActions: actions,
  entity,
  message: messageToTheOwner,
  internalNote,
  type: lockOrUnlock,
})

/**
 * Creates an Action to lock a lockable object.
 */
export const lockModelAction = (entity: ILockableModel, messageToTheOwner: string, internalNote: string, actions: IFormikActions): ILockUnlockAction =>
  lockOrUnlockModelAction(LockUsecases.LockEntity, entity, messageToTheOwner, internalNote, actions)

/**
 * Creates an Action to unlock a lockable object.
 */
export const unlockModelAction = (entity: ILockableModel, messageToTheOwner: string, internalNote: string, actions: IFormikActions): ILockUnlockAction =>
  lockOrUnlockModelAction(LockUsecases.UnlockEntity, entity, messageToTheOwner, internalNote, actions)

/**
 * Creates a unique usecaseKey for Locking/Unlocking an entity.
 */
export const usecaseKeyForLockUnlockEntity = (entity: ILockableModel, lockOrUnlock: LockUsecases): string =>
  lockOrUnlock + idFromIModelOrIRI(entity?.["@id"])
