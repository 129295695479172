import { FormikHelpers } from "formik"

import { stringAsHtmlId } from "./util-importless"

/**
 * returns an ID for fields with error
 */
export const errorIdForField = (fieldName: string): string => "error_on_field_" + stringAsHtmlId(fieldName)

/**
 * IFormikActions are used in every Formik-based form to report back succesful or non-successful submissions
 */
export interface IFormikActions extends Partial<FormikHelpers<any>> {
  /**
   * Coder should define a function and assign it to onSucces in the onSubmit-Function for a Formik-Form,
   * that should be called in the corresponding Saga, when a request was succesful.
   */
  onSuccess?: (...args: any) => void
}