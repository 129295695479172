import { Action } from "redux"

import { IFundApplication, IProject, IProposal, ITeamEmail, ProposalTransitionState } from "@api/schema"
import { IFormikActions } from "@basics/form"


/*
 Actions for myProjects are those Actions, that a user may trigger to update those projects, he has access.
 */

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum MyProjectsActionTypes {
  CreateProjectPdf = "CREATE_PROJECT_PDF",
  ActivateProposal = "ACTIVATE_PROPOSAL",
  TransitionProposal = "TRANSITION_PROPOSAL",
  CreateProposalPdf = "CREATE_PROPOSAL_PDF",
  EmailTeam = "EMAIL_TEAM",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IMyProjectsAction extends Action {
  type: MyProjectsActionTypes
}

export interface ICreateProjectPdfAction extends IMyProjectsAction {
  project: IProject
  type: MyProjectsActionTypes.CreateProjectPdf
}

export interface IActivateProposalAction extends IMyProjectsAction {
  actions: IFormikActions
  proposal: IProposal | IFundApplication
  type: MyProjectsActionTypes.ActivateProposal
}

export interface ITransitionProposalAction extends IMyProjectsAction {
  actions: IFormikActions
  proposal: IProposal | IFundApplication
  transition: ProposalTransitionState
  type: MyProjectsActionTypes.TransitionProposal
}

export interface ICreateProposalPdfAction extends IMyProjectsAction {
  proposal: IProposal | IFundApplication
  type: MyProjectsActionTypes.CreateProposalPdf
}

export interface IEmailTeamAction extends IMyProjectsAction {
  actions: IFormikActions
  email: ITeamEmail
  project: IProject
  type: MyProjectsActionTypes.EmailTeam
}

export type MyProjectsActions =
  // | ILoadMyProjectsAction
  // | IUploadProjectPictureAction
  // | IUploadProjectVisualizationAction  | ICreateProjectPdfAction
  | IActivateProposalAction
  | ITransitionProposalAction
  | IEmailTeamAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const createProjectPdfAction = (project: IProject): ICreateProjectPdfAction => ({
  project,
  type: MyProjectsActionTypes.CreateProjectPdf,
})

export const activateProposalAction = (proposal: IProposal | IFundApplication, actions: IFormikActions): IActivateProposalAction => ({
  actions,
  proposal,
  type: MyProjectsActionTypes.ActivateProposal,
})

export const transitionProposalAction = (proposal: IProposal | IFundApplication, transition: ProposalTransitionState, actions: IFormikActions): ITransitionProposalAction => ({
  actions,
  proposal,
  transition,
  type: MyProjectsActionTypes.TransitionProposal,
})

export const createProposalPdfAction = (proposal: IProposal | IFundApplication): ICreateProposalPdfAction => ({
  proposal,
  type: MyProjectsActionTypes.CreateProposalPdf,
})

export const emailTeamAction = (project: IProject, email: ITeamEmail, actions: IFormikActions): IEmailTeamAction => ({
  actions,
  email,
  project,
  type: MyProjectsActionTypes.EmailTeam
})