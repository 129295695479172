import { all } from "redux-saga/effects"

import { oidcWatcherSaga } from "@modules/oidc/src"
import { challengesWatcherSaga } from "@redux/usecases/challengesForManagement"
import { lockOrUnlockWatcherSaga } from "@redux/usecases/lock/saga"
import { onboardingWatcherSaga } from "@redux/usecases/onboarding/sagas"
import { projectStateWatcherSaga } from "@redux/usecases/projectState"
import { reportEntityWatcherSaga } from "@redux/usecases/report/saga"
import { transitionWatcherSaga } from "@redux/usecases/transitions/saga"
import { userAccountWatcherSaga } from "@redux/usecases/userAccount/sagas"

import { authWatcherSaga } from "./auth"
import { currentUserWatcherSaga } from "./currentUser"
import { feedbackWatcherSaga } from "./feedback"
import { generalWatcherSaga } from "./generalSaga"
import { ideaWatcherSaga } from "./idea"
import { myProjectsWatcherSaga } from "./myProjects"
import { platformWatcherSaga } from "./platform"
import { processesWatcherSaga } from "./processes"
import { projectWatcherSaga } from "./project"
import { registrationWatcherSaga } from "./registration"
// import { teamUploadsWatcherSaga } from "./teamUploads"
import { usersWatcherSaga } from "./users"
import { verificationWatcherSaga } from "./verification"

// ALL sagas must be registered here!

export function* rootSaga(): any {
  yield all([
    authWatcherSaga(),
    currentUserWatcherSaga(),
    challengesWatcherSaga(),
    feedbackWatcherSaga(),
    generalWatcherSaga(),
    myProjectsWatcherSaga(),
    ideaWatcherSaga(),
    lockOrUnlockWatcherSaga(),
    projectWatcherSaga(),
    onboardingWatcherSaga(),
    platformWatcherSaga(),
    projectStateWatcherSaga(),
    processesWatcherSaga(),
    reportEntityWatcherSaga(),
    registrationWatcherSaga(),
    // teamUploadsWatcherSaga(),
    transitionWatcherSaga(),
    userAccountWatcherSaga(),
    usersWatcherSaga(),
    verificationWatcherSaga(),
    oidcWatcherSaga(),
  ])
}

