import { Action } from "redux"

import { IPasswordReset, IVerification as IVerification } from "@api/schema"
import { IFormikActions } from "@basics/form"

/*
Actions for the verification of a user account
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum VerificationActionTypes {
  ConfirmAccount = "CONFIRM_ACCOUNT",
  ConfirmChangeEmail = "CONFIRM_EMAIL",
  ResetPassword = "RESET_PASSWORD",
  ResetVerificationResult = "RESET_VERIFICATION_RESULT",
  VerificationOperationSuccess = "REQUEST_VERIFICATION_OPERATION_SUCCESS"
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IVerificationAction extends Action {
  type: VerificationActionTypes
}

export interface IConfirmAccountAction extends IVerificationAction {
  actions: IFormikActions
  data: IVerification
  type: VerificationActionTypes.ConfirmAccount
}

export interface IConfirmChangeEmailAction extends IVerificationAction {
  actions: IFormikActions
  data: IVerification
  type: VerificationActionTypes.ConfirmChangeEmail
}

export interface IResetPasswordAction extends IVerificationAction {
  actions: IFormikActions
  data: IPasswordReset
  type: VerificationActionTypes.ResetPassword
}

export interface IResetVerificationResultAction extends IVerificationAction {
  type: VerificationActionTypes.ResetVerificationResult
}

export type VerificationActions =
  IConfirmAccountAction
  | IConfirmChangeEmailAction
  | IResetPasswordAction
  | IResetVerificationResultAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const confirmAccountAction = (data: IVerification, actions: IFormikActions): IConfirmAccountAction => ({
  actions,
  data,
  type: VerificationActionTypes.ConfirmAccount,
})

export const confirmChangeEmailAction = (data: IVerification, actions: IFormikActions): IConfirmChangeEmailAction => ({
  actions,
  data,
  type: VerificationActionTypes.ConfirmChangeEmail,
})

export const resetPasswordAction =
  (data: IPasswordReset, actions: IFormikActions): IResetPasswordAction => ({
    actions,
    data,
    type: VerificationActionTypes.ResetPassword,
  })

export const resetVerificationResultAction = (): IResetVerificationResultAction => ({
  type: VerificationActionTypes.ResetVerificationResult,
})
