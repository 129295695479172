import { Action } from "redux-saga"

import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IOperationResultOutput, ReportableModel } from "@api/schema"
import { IFormikActions } from "@basics/form"

import { IAbuseReportDTO } from "./schema-definitions"

/** result string expected from the API when object was reported */
export const REPORT_API_RESULT = "Report received."


/**
 * Definition of the interaction with the host application backend API for performing report processes
 * to be implemented by the client.
 */
export interface IReportEntityApi<Output = IOperationResultOutput> {
  /**
   * Reports a reportable entity by addressing its /report endpoint.
   *
   * @param reportableEntity an entity that is reportable
   * @param abuseReportDto message why the entity is reported for abuse
   * @returns a standard operation result output
   */
  reportEntity: (reportableEntity: ReportableModel, abuseReportDto: IAbuseReportDTO) => Promise<Output>
}

/**
 * This enum defines the usecase for reporting an entity.
 */
export enum ReportUsecases {
  ReportEntity = "_report_entity_",
}

/**
 * Action type for reporting an entity.
 */
export interface IReportEntityAction extends Action {
  /** formik actions to interact with the triggering form */
  callbackActions: IFormikActions
  /** entity to be reported */
  entity: ReportableModel
  /** message to report a misuse + (optional) name and email of the reporting user */
  abuseReport: IAbuseReportDTO
  /** type of the action */
  type: ReportUsecases
}

/**
 * Creates an Action to report a reportable object.
 */
export const reportEntityAction = (entity: ReportableModel, abuseReport: IAbuseReportDTO, actions: IFormikActions): IReportEntityAction =>
({
  entity,
  abuseReport,
  callbackActions: actions,
  type: ReportUsecases.ReportEntity
})

/**
 * Creates a unique usecaseKey for reporting an entity.
 */
export const usecaseKeyForReportEntity = (entity: ReportableModel): string =>
  ReportUsecases.ReportEntity + iriFromIModelOrIRI(entity)
