import Link from "next/link"
import { Col, Row } from "reactstrap"

import { Routes } from "@basics/routes"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { hashIdContactPageBugReport } from "@services/processHelper"
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config"

import LanguageSelector from "./Footer/LanguageSelector"
import SocialLinks from "./SocialLinks"



const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  isFrontPage?: boolean
}

/**
 * This component provides the page-footer of the client-application, used by BaseLayout
 */
const Footer: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()

  return (
    <footer>
      <Row className="footer-content">
        <Col className="footer__side-cols">
          {props.isFrontPage && <SocialLinks />}
        </Col>
        <Col className="footer-links">
          <Link href={Routes.About} title={t("base-layout", "aboutTitle")}>
            {t("base-layout", "about")}
          </Link>{" "}
          |{" "}
          <Link href={Routes.Contact}>
            {t("base-layout", "contact")}
          </Link>{" "}
          |{" "}
          <Link href={Routes.Imprint}>
            {t("base-layout", "imprint")}
          </Link>{" "}
          |{" "}
          {!SINN_PROTOTYPE_CLIENT_CONFIG_USED && <>
            <Link href={Routes.TermsOfUse}>
              {t("base-layout", "imprint")}
            </Link>{" "}
            |{" "}
          </>}
          <Link href={Routes.DataProtection}>
            {t("base-layout", "dataProtection")}
          </Link>{" "}
          |{" "}
          <Link href={Routes.Netiquette}>
            {t("base-layout", "netiquette")}
          </Link>{" "}
          |{" "}
          {!SINN_PROTOTYPE_CLIENT_CONFIG_USED && <>
            <Link href={Routes.Registration}>
              {t("base-layout", "register")}
            </Link>{" "}
            | {" "}
          </>}
          <Link href={Routes.Contact + hashIdContactPageBugReport}>
            {t("base-layout", "bugReport")}
          </Link>
        </Col>
        <Col className="footer__side-cols">
          <LanguageSelector />
        </Col>
      </Row>
    </footer>
  )
}

export default withDynamicNamespaces<IProps>(Footer, usedNamespaces)
