import { Action } from "redux"

import { IProjectCreationDTO } from "@api/schema-dto"
import { IFormikActions } from "@basics/form"

/*
Actions for creating a new project
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum ProjectActionTypes {
  // don't use "CREATE_PROJECT", it collides with the general data actions -> reduxTypes.ts (ProjectActionTypes)
  CreateNewProject = "CREATE_NEW_PROJECT",
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IProjectAction extends Action {
  type: ProjectActionTypes
}

export interface ICreateProjectAction extends IProjectAction {
  actions: IFormikActions
  projectCreationDto: IProjectCreationDTO
  type: ProjectActionTypes.CreateNewProject
}

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const createProjectAction = (projectCreationDto: IProjectCreationDTO, actions: IFormikActions): ICreateProjectAction => ({
  actions,
  projectCreationDto,
  type: ProjectActionTypes.CreateNewProject,
})
