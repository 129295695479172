import { Action } from "redux-saga"

import { TransitionDTO } from "@api/schema-dto"
import { IFormikActions } from "@basics/form"

/**
 * This enum defines the usecases and its usecase key for "transitions".
 */
export enum TransitionUsecases {
  /**
   * type (and usecase key) for transitioning an entity from one state to another
   */
  TransitionEntity = "_usecase_transition",
}

// #region generic transition action
/**
 * Type of an Action for triggering a transition of an entity.
 * @template E type of the Entity for which the transition is prepared
 * @template TransitionEnum list of transitions that may be performed
 */
export interface ITransitionAction<E, TransitionEnum> extends Action {
  /** formik actions to interact with the triggering form */
  actions: IFormikActions
  /** entity to be transitioned */
  entity: E
  /** transition to be performed on the entity */
  transition: TransitionDTO<TransitionEnum>
  /** type of the action, to be used as usecase key */
  type: TransitionUsecases
}

/**
 * Action for triggering a transition.
 *
 * @param entity Entity to be transitioned.
 * @param transition Transition to be performed on that entity.
 * @param actions FormikActions to respond to the calling form
 *
 * @template E type of the entity for which the action should be performed
 * @template Transition transition to be performed on the entity
 * @returns an Action to be dispatched
 */
export const entityTransitionAction = <E, Transition>(
  entity: E,
  transition: TransitionDTO<Transition>,
  actions: IFormikActions
): ITransitionAction<E, Transition> => ({
  actions,
  entity,
  transition,
  type: TransitionUsecases.TransitionEntity,
})

// #endregion